import { css } from '@emotion/react'
import { Link, graphql } from 'gatsby'
import {
  ComponentPropsWithoutRef,
  useEffect,
  useRef,
  useState,
} from 'react'

import { useNavContext } from '@/features/layout'
import { bezier, mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'
import { assertIsNode } from '@/utils/assertions'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data: Queries.NavNetworkGroupFragment | null
  breakpoint: NonNullable<Queries.NavQuery['datoCmsNav']>['breakpoint']
}

const NavNetworkGroup = ({
  data,
  breakpoint,
  ...props
}: Props): JSX.Element => {
  const { setMobileNavIsOpen } = useNavContext()
  const [isOpen, setIsOpen] = useState(false)
  const handleClick = () => {
    setMobileNavIsOpen(false)
    setIsOpen(false)
  }
  const containerRef = useRef<HTMLDivElement | null>(null)
  const handleClickOutside = (e: MouseEvent) => {
    assertIsNode(e.target)
    if (isOpen && !containerRef.current?.contains(e.target)) {
      setIsOpen(false)
    }
  }
  useEffect(() => {
    addEventListener('click', handleClickOutside, { passive: true })
    return () => {
      removeEventListener('click', handleClickOutside)
    }
  })
  const styles = {
    container: css`
      margin: 0;
      padding: 0.5em 0;
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;
      z-index: 1;
      @media (max-width: ${breakpoint}px) {
        margin: 1em 0;
        display: grid;
        justify-items: center;
        text-align: center;
        font-size: var(--fs-21);
        ${mq().ms} {
          font-size: var(--fs-18);
        }
      }
    `,
    button: css`
      position: relative;
      color: var(--link-color, currentColor);
      --font-weight: 500;
      font-weight: var(--font-weight);
      display: block;
      max-width: fit-content;

      text-transform: uppercase;
      letter-spacing: 0.025em;
      text-decoration: none;
      line-height: 1;
      z-index: 1;
      @media (hover: hover) {
        &:hover {
          color: ${colors.tealLight};
        }
      }
      @media (max-width: ${breakpoint}px) {
        pointer-events: none;
        cursor: default;
        @media (hover: hover) {
          &:hover {
            color: inherit;
          }
        }
      }
    `,
    list: css`
      position: absolute;
      z-index: 0;
      background: ${colors.tealDark};
      padding: 1em 1em 0.5em;
      margin: 0 -1em;
      list-style: none;
      transition:
        opacity 300ms ease,
        transform 300ms ${bezier.easeOut};
      pointer-events: none;
      opacity: 0;
      transform: translate3d(0, -3em, 0);
      ${isOpen &&
      css`
        pointer-events: all;
        opacity: 1;
        transform: translate3d(0, 0, 0);
      `};
      @media (max-width: ${breakpoint}px) {
        position: relative;
        display: block;
        pointer-events: all;
        opacity: 1;
        transform: none;
        transition: none;
        background: transparent;
        margin: 1em 0 0;
        padding: 0.5em 0;
        display: grid;
        justify-items: center;
        border-bottom: 1px solid ${colors.gray85};
        border-top: 1px solid ${colors.gray85};
      }
    `,
    listItem: css``,
    link: css`
      display: block;
      width: fit-content;
      color: #fff;
      text-decoration: none;
      padding: 0.25em 0;
      margin: 0.25em 0;
      @media (hover: hover) {
        &:hover {
          color: ${colors.apricotLight};
        }
      }
      @media (max-width: ${breakpoint}px) {
        color: ${colors.teal};
        @media (hover: hover) {
          &:hover {
            color: ${colors.apricot};
          }
        }
      }
    `,
  }
  return (
    <div
      css={styles.container}
      ref={containerRef}
      {...props}
    >
      <button
        onClick={() => setIsOpen(prev => !prev)}
        css={styles.button}
      >
        <span>Network</span>
      </button>
      <ul css={styles.list}>
        {data?.networkLinks?.map(link => (
          <li
            key={link?.slug}
            css={styles.listItem}
          >
            <Link
              to={`/${link?.slug}/`}
              css={styles.link}
              onClick={handleClick}
            >
              <span>{link?.siteName}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
export const NavNetworkGroupFragment = graphql`
  fragment NavNetworkGroup on DatoCmsNavNetworkGroup {
    __typename
    id: originalId
    networkLinks {
      siteName
      slug
    }
  }
`
export default NavNetworkGroup
